<template>
  <v-app>
    <v-app-bar app color="white" flat>
      <img src="@/assets/icon128x128.png" width="32" height="32" class="mr-2">
      <div>
        {{ $t("GMailSend Address Checker") }}
        <span class="d-none d-sm-inline">{{ $t("- 誤送信防止 -") }}</span>
      </div>
      <v-spacer/>
      <v-menu offset-y open-on-hover>
        <template v-slot:activator="{ on }">
          <v-btn text color="default" class="d-none d-sm-inline mr-1" v-on="on">{{ locales[locale] }}<v-icon>mdi-chevron-down</v-icon></v-btn>
        </template>
        <v-list>
          <v-list-item v-for="(text, value) in locales"
                       :key="value"
                       @click="locale = value">
            <v-list-item-title>{{ text }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn text color="default" class="d-none d-md-inline mr-2" @click="goUsageSection">{{ $t("使い方") }}</v-btn>
      <v-btn text color="default" class="d-none d-md-inline mr-2" @click="goDonateSection">{{ $t("寄付する") }}</v-btn>
      <v-btn outlined color="primary" class="d-none d-sm-inline" @click="install">{{ $t("インストール") }}</v-btn>
      <v-btn icon @click="drawer = true" class="d-inline d-md-none"><v-icon>mdi-menu</v-icon></v-btn>
    </v-app-bar>
    <v-navigation-drawer app
                         bottom
                         touchless
                         :overlay-opacity="0.1"
                         temporary
                         v-model="drawer">
      <v-toolbar flat>
        <v-spacer/>
        <v-btn icon @click="drawer = false"><v-icon>mdi-close</v-icon></v-btn>
      </v-toolbar>
      <v-list>
        <v-list-item @click="install">
          <v-list-item-title class="primary--text">{{ $t("インストール") }}</v-list-item-title>
        </v-list-item>
        <v-list-item @click="goUsageSection">
          <v-list-item-title>{{ $t("使い方") }}</v-list-item-title>
        </v-list-item>
        <v-list-item @click="goDonateSection">
          <v-list-item-title>{{ $t("寄付する") }}</v-list-item-title>
        </v-list-item>
        <v-list-group>
          <template v-slot:activator>
            <v-list-item-title>{{ $t("言語") }}: {{ locales[locale] }}</v-list-item-title>
          </template>
            <v-list-item v-for="(text, value) in locales"
                        dense
                        :key="value"
                        @click="locale = value">
              <v-list-item-icon><v-icon v-if="locale == value">mdi-check</v-icon></v-list-item-icon>
              <v-list-item-title>{{ text }}</v-list-item-title>
            </v-list-item>
        </v-list-group>
      </v-list>
    </v-navigation-drawer>
    <v-main app>
      <router-view ref="mainComponent"/>
    </v-main>
  </v-app>
</template>
<script>
import utils from '@/utils/utils';
import locales from '@/lang/locales.json'
export default {
  data () {
    return {
      drawer: false,
      locales
    }
  },
  computed: {
    locale: {
      get: function () {
        return this.$store.state.locale
      },
      set: function (newLocale) {
        this.$store.commit("setLocale", newLocale)
        this.$i18n.locale = newLocale
      }
    }
  },
  methods: {
    install: function () {
      const link = this.$t(utils.installURL() + "インストールリンク");
      window.open(link, '_blank');
    },
    goUsageSection: function () {
      if (this.$refs.mainComponent.scrollTo) {
        this.$refs.mainComponent.scrollTo("#usage_section");
      }
    },
    goDonateSection: function () {
      if (this.$refs.mainComponent.scrollTo) {
        this.$refs.mainComponent.scrollTo("#donate_section");
      }
    }
  }
}
</script>
